$form-select-indicator-color: white;
$btn-active-box-shadow: none;
$dropdown-link-hover-bg: transparent;
@import "~bootstrap/scss/bootstrap";

.flickity-fullscreen-button {
  display: none !important;
}

.bg {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  background-color: #e5e5f7;
  opacity: 0.1;
  background-image: -o-radial-gradient(center center, circle, #000000, #e5e5f7), -o-repeating-radial-gradient(center center, circle, #000000, #000000, 40px, transparent 80px, transparent 40px);
  background-image: radial-gradient(circle at center center, #000000, #e5e5f7), repeating-radial-gradient(circle at center center, #000000, #000000, 40px, transparent 80px, transparent 40px);
  background-blend-mode: multiply;
}

.nw {
  word-break: keep-all;
  white-space: nowrap;
}

html,
body,
#root,
.App {
  width: 100%;
  margin: 0 !important;
}

.show>.btn,
.show>.btn:hover,
.show>.btn:active,
.show>.btn:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

.nav_button {
  background-color: transparent;
  border: none;
  color: white;
  border-radius: 0;
}

.dropdown-toggle::after {
  margin-left: 1.3em;
}

.nav_arrow {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.nav_select:hover,
.nav_select:active,
.nav_select:focus,
.nav_arrow:hover,
.nav_arrow:active,
.nav_arrow:focus,
.nav_button:hover,
.nav_button:active,
.nav_button:focus {
  background-color: transparent;
  color: white;
  box-shadow: none;
  border-color: white;
  outline: 0;
}

.nav_button:hover,
.nav_arrow:hover,
.nav_select:hover {
  border-bottom-color: blue;
}

.nav_button:active,
.nav_arrow:active,
.nav_select:active {
  border-bottom-color: aqua;
}

.nav_select {
  background-color: transparent;
  border: none;
  color: white;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.dropdown_select .dropdown-menu {
  background-color: transparent;
  border: none;
}

.dropdown_select .dropdown-item {
  background-color: rgb(33, 37, 41);
  color: white;
  border-bottom: white 1px solid;
}

.dropdown_select .dropdown-item:hover {
  border-color: blue;
}

.dropdown_select .dropdown-item:active {
  border-color: aqua;
}

.sort_label {
  padding: 0.375rem 0rem 0.375rem 0.75rem;
  border-bottom: 1px solid white;
  margin-right: 0;
}

.selection>input {
  border-radius: 3rem !important;
}

.selection {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 25px;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 3rem;
  z-index: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#imageViewer {
  height: 400px;
  position: relative;
}

.carousel {
  background: #FAFAFA;
  display: none;
}

.carousel-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66%;
  height: 400px;
  margin-right: 10px;
  background: #333;
}

.carousel-cell-image {
  display: block;
  max-height: 100%;
  margin: 0 auto;
  max-width: 100%;
  transition: opacity 0.4s;
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.carousel.is-fullscreen {
  display: block;
}

.thumbnail {
  cursor: pointer;
}

.thumbnail:hover {
  transform: scale(1.01);
  box-shadow: 0px 0px 9px 7px rgba(255, 255, 255, 1);
  z-index: 2;
}

#carousel-custom-buttons {
  color: hsla(0, 0%, 100%, .75);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  font-size: 44px;
  white-space: nowrap;
  background-color: black;
  padding: 0.15rem;
  opacity: 0.8;
  user-select: none;
}

.carousel-button {
  display: inline-block;
}

#carousel-close-button>div:hover {
  cursor: pointer;
  color: #fff;
}

.carousel-button:hover {
  cursor: pointer;
  color: #fff;
}

.flickity-button {
  left: unset !important;
  right: unset !important;
  display: inline-block;
  position: relative;
  transform: unset;
  top: unset;
}

#carousel-close-button {
  position: fixed;
  top: 25px;
  right: 25px;
  z-index: 2;
  font-size: 44px;
  opacity: 0.8;
  color: hsla(0, 0%, 100%, .75);
  user-select: none;
}

@media only screen and (max-width: 992px) {
  #carousel-custom-buttons {
    font-size: 38px;
  }

  .flickity-button {
    width: 38px;
    height: 38px;
  }
}

@media only screen and (max-width: 768px) {
  #carousel-custom-buttons {
    font-size: 34px;
  }

  .flickity-button {
    width: 34px;
    height: 34px;
  }
}

#logoutIcon {
  margin-left: 4px;
  vertical-align: sub;
  width: 20px;
  height: 20px;
}

.photo_checked {
  position: relative;
  transform: scale(0.985);
  border: 3px solid blue;
}

.photo {
  position: relative;
}

.photo_icon_checked {
  filter: drop-shadow(1px 1px 6px white);
  color: blue;
}

.photo_icon {
  filter: drop-shadow(1px 1px 6px black);
  color: white;
}

.progressiveImage {
  margin-bottom: 0.5rem;
  transition: opacity .15s linear;
  box-shadow: 0px 0px 10px 4px rgba(202, 202, 202, 1);
}

.progressiveImage_checked {
  margin-bottom: 0;
  transform: none;
  box-shadow: none;
  transition: opacity .15s linear;
}

#imageEditor {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#progress_div {
  height: "20px";
  flex-direction: "column";
  align-items: "center";
  justify-content: "center";
  display: flex;
}

#progressBar {
  width: 100%;
  height: 20px
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.no-break {
  white-space: nowrap;
  word-break: keep-all;
}

.c-ffd3d3 {
  color: #ffd3d3;
}

#upload_select_buttons {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%
}

#transferFile {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
}

.bg-95ff95 {
  background-color: #95ff95;
}

.bg-ff9595 {
  background-color: #ff9595;
}

.bg-c2ffc2 {
  background-color: #c2ffc2;
}

.bg-ffc2c2 {
  background-color: #ffc2c2;
}

.bg-initial {
  background-color: initial;
}

#zone {
  width: fit-content;
  background-color: white;
}

.text-last-center {
  text-align-last: center;
}

.c-green {
  color: green;
}

.c-red {
  color: red;
}

.h-fit {
  height: fit-content;
}

.h-110px {
  height: 110px;
}